import React from "react"
import CustomPage from "../components/customPage/CustomPage"
import Seo from "../components/seo/Seo"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { graphql, useStaticQuery } from "gatsby"

export default function About() {
  const data = useStaticQuery(graphql`
    query {
      allContentfulAbout {
        edges {
          node {
            description {
              json
            }
          }
        }
      }
    }
  `)
  
  return (
    <>
      <Seo title="About" pathname="about" />
      <CustomPage title="About me">
        <div className="md-about">
          <div className="md-about__container">
            <div className="md-about__content">
              {documentToReactComponents(data.allContentfulAbout.edges[0].node.description.json)}
            </div>
          </div>
        </div>
      </CustomPage>
    </>
  )
}
